<template>
    <div>
        <div class="bkColor">
            <headTitle :title="reimbursementDetail.projectName || reimbursementDetail.userName + title"
                :routerPath="routerPath"></headTitle>
            <div class="projectTitle">
                <div class="T" v-if="reimbursementDetail.projectName">{{ reimbursementDetail.projectName }}的项目报销详情</div>
                <div class="T" v-else>{{ reimbursementDetail.userName }}的报销</div>
                <div class="D">{{ getReviewedStatus(reimbursementDetail.state) }}</div>
                <div style="font-size:14px">流水号: {{ reimbursementDetail.reimbursementNo }}</div>
                <img v-show="reimbursementDetail.isSettle == 1" class="I" src="../assets/icon/ytg_img.png" alt="">
                <img v-show="reimbursementDetail.isSettle == 2" class="I" src="../assets/icon/yjj_img.png" alt="">
            </div>
            <div class="projectBody">
                <van-field v-model="projectBelong" label="归属单位" readonly />
                <van-field v-model="reimbursementDetail.userName" label="报销人" placeholder="请输入报销人" readonly />
                <div class="chart">
                    <div class="title">报销明细<van-button round block type="info" native-type="button"
                            style="width: 120px;float: right;" @click="downloadAll" size="small"
                            v-if="equipment">下载全部文件</van-button></div>

                    <div style="overflow-x: auto; overflow-y: auto">
                        <table class="table" :border="1" cellspacing="0">
                            <tr>
                                <td class="label">序号</td>
                                <td class="label">费用类别</td>
                                <td class="label">详细类别</td>
                                <td class="label">金额（元）</td>
                                <td class="label">发票数量</td>
                                <td class="label">发票编号</td>
                                <td class="label">报销时间开始和结束时间</td>
                                <td class="label">备注</td>
                                <td class="label">附件</td>
                            </tr>
                            <tr v-for="(item, index) in reimbursementDetail.detailList" :key="index">
                                <td class="label">{{ index + 1 }}</td>
                                <td class="label">{{ item.reimbursementName }}</td>
                                <td class="label">{{ item.detailTypeName }}</td>
                                <td class="label">{{ item.price }}</td>
                                <td class="label">{{ item.billNum }}</td>
                                <td class="label">{{ item.billNo }}</td>
                                <td class="label">{{ item.reimbursementTime }}</td>
                                <td class="label">{{ item.description }}</td>
                                <td class="label">
                                    <div style="margin: 0 5px" v-for="it in item.fileList" :key="it.url"
                                        @click="reViewFile(it.url)">
                                        {{ it.fileName }}
                                    </div>
                                </td>
                                <!-- <td class="label">{{ item }}</td> -->
                            </tr>
                        </table>
                        <div v-show="isReviewImg"
                            style="position: fixed;top: 0;left:0;right:0;bottom:0;margin:auto;width: 100%;height: 100%;;background: #323233;z-index: 1;overflow: auto;"
                            @click="isReviewImg = false">
                            <img :src="reViewImg" alt=""
                                style="width: 100%;position: absolute;top: 0;left: 0;bottom: 0;right: 0;">
                        </div>
                    </div>
                </div>
                <van-field v-model="reimbursementDetail.totalPrice" label="合计" placeholder="请输入合计" readonly />
                <van-field v-model="reimbursementDetail.reason" rows="1" autosize label="报销备注" type="textarea" readonly />
            </div>

            <process-details :list="reimbursementDetail.projectReviewedList" :avatar="reimbursementDetail.avatar"
                :copyList="reimbursementDetail.copyList" :createTime="reimbursementDetail.createTime"
                :userName="reimbursementDetail.userName"
                :routerPath="isProject ? `/reimbursementDetails?id=${id}` : `/reimbursementDetails?id=${id}&route=/nonProject`"></process-details>


            <!-- <div class="projectFoot"
                v-if="powerList.includes('2') && reimbursementDetail.state == 1 && reimbursementDetail.isSettle == 0">
                <van-button round block color="#CCCCCC" native-type="button" style="width: 35vw"
                    @click="finalConfirmation(2)">财务拒绝</van-button>
                <van-button round block type="info" native-type="button" style="width: 35vw"
                    @click="finalConfirmation(1)">财务确认</van-button>
            </div> -->



            <!-- <van-goods-action>
                <van-goods-action-icon v-if="equipment" icon="description" text="导出PDF" @click="pdf = true" />
                <van-goods-action-icon v-if="(reimbursementDetail.state == 0 && userId == reimbursementDetail.userId)"
                    icon="revoke" text="撤销" @click="reimbursementRevoke" />
                <van-goods-action-icon icon="chat-o" text="评论" @click="isComment = true" />
                <van-goods-action-icon v-if="(reimbursementDetail.state == 2 && userId == reimbursementDetail.userId)"
                    icon="description" text="重新提交" @click="resubmit" />
            </van-goods-action> -->

            <van-popup v-model="isComment" round closeable @click-close-icon="isComment = false">
                <sandBox :formId="id" :type="4"
                    :routerPath="isProject ? `/reimbursementDetails?id=${id}` : `/reimbursementDetails?id=${id}&route=/nonProject`"
                    @succesSend="pageUpdate" :promoterId="reimbursementDetail.userId"></sandBox>
            </van-popup>



            <!-- <sandBox :formId="id" :type="4"
                :routerPath="isProject ? `/reimbursementDetails?id=${id}` : `/reimbursementDetails?id=${id}&route=/nonProject`"
                @succesSend="pageUpdate" :promoterId="reimbursementDetail.userId"></sandBox>

            <div class="projectFoot">
                <van-button round block type="info" native-type="button" style="width: 35vw"
                    @click="exportPDF">导出pdf</van-button>

            </div> -->
            <van-popup v-model="pdf">
                <div id="printer">
                    <p>项目报销单</p>
                    <div style="text-align:left;font-size: 22px;font-weight: 600;">流水号：{{
                        reimbursementDetail.reimbursementNo }}
                    </div>
                    <div style="text-align:left;font-weight: 600;margin-bottom: 10px;font-size: 30px;">单位：{{
                        reimbursementDetail.belong == 1 ? "广州大美自然文化科技有限公司" : "广东中创科技信息有限公司" }}</div>
                    <table style="width:100%">
                        <tr>
                            <td class="bold">时间</td>
                            <td colspan="2" class="bold">项目</td>
                            <td class="bold">报销类型</td>
                            <td colspan="2" class="bold">详细类型</td>
                            <td class="bold">金额(元)</td>
                            <td class="bold">发票等单据支出编号</td>
                            <td class="bold">发票等单据数量</td>
                            <td colspan="2" class="bold">备注</td>
                        </tr>
                        <tr v-for="it in reimbursementDetail.detailList" :key="it.id">
                            <td>{{ it.reimbursementTime }}</td>
                            <td colspan="2">{{ reimbursementDetail.projectName || '非项目' }}</td>
                            <td>{{ it.reimbursementName }}</td>
                            <td colspan="2">{{ it.detailTypeName }}</td>
                            <td>{{ it.price }}</td>
                            <td>{{ it.billNo }}</td>
                            <td>{{ it.billNum }}</td>
                            <td colspan="2">{{ it.description }}</td>
                        </tr>
                        <tr>
                            <td colspan="6" class="bold">合计</td>
                            <td>{{ reimbursementDetail.totalPrice }}</td>
                            <td></td>
                            <td></td>
                            <td colspan="2"></td>
                        </tr>

                    </table>


                    <process-details :list="reimbursementDetail.projectReviewedList" :avatar="reimbursementDetail.avatar"
                        :copyList="reimbursementDetail.copyList" :createTime="reimbursementDetail.createTime"
                        :userName="reimbursementDetail.userName" :promoterId="reimbursementDetail.userId"
                        :isPdf="true"></process-details>

                </div>
                <div style="display:flex;justify-content: center;align-items: center;">
                    <van-button round block type="info" native-type="button" style="width: 30vw;;"
                        @click="exportPDF">确定导出</van-button>
                    <van-button round block type="info" native-type="button" style="width: 30vw;" color="#CCCCCC"
                        @click="pdf = false">取消导出</van-button>
                </div>

            </van-popup>

        </div>
        <div class="footContent">
            <div class="footButton" @click="pdf = true" v-if="equipment">
                <van-icon name="description" size="16px" />
                导出PDF
            </div>
            <div class="footButton" @click="reimbursementRevoke"
                v-if="(reimbursementDetail.state == 0 && userId == reimbursementDetail.userId)">
                <van-icon name="revoke" size="16px" />
                撤销
            </div>
            <div class="footButton" @click="isComment = true">
                <van-icon name="chat-o" size="16px" />
                评论
            </div>
            <div class="footButton" @click="resubmit"
                v-if="(reimbursementDetail.state == 2 && userId == reimbursementDetail.userId)">
                <van-icon name="records" size="16px" />
                重新提交
            </div>
            <div v-if="powerList.includes('2') && reimbursementDetail.state == 1 && reimbursementDetail.isSettle == 0"
                style="margin-left: auto">
                <van-button @click=" finalConfirmation(2)" style="margin-right:20px">财务拒绝</van-button>
                <van-button type="info" @click="finalConfirmation(1)">财务同意</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import headTitle from "../components/headTitle.vue";
import { Field, Toast, Button, Popup, GoodsAction, GoodsActionIcon, Dialog, Icon } from 'vant'
import Vue from 'vue';
Vue.use(Field)
Vue.use(Button)
Vue.use(Popup)
Vue.use(GoodsAction);
Vue.use(GoodsActionIcon);
Vue.use(Dialog)
Vue.use(Icon)

import { getConfig } from '../api/api'
import { timeChange, projectStatusText } from '../units/units'
import { getReimbursement, reimbursementUpdateState, reimbursementDeltel } from "../api/reimbursement"
import { getToken } from '../units/auth'
import processDetails from '../components/processDetails.vue'
import sandBox from "../components/inputBox/sandBox.vue";

import { ddShare } from "../units/dd";
export default {
    name: 'reimbursementDetails',
    components: {
        headTitle,
        processDetails,
        sandBox
    },
    mounted() {
        this.id = this.$route.query.id
        this.routerPath = this.$route.query.route ? this.$route.query.route : '/pjReimbursement'
        this.isProject = this.$route.query.route ? true : false
        this.getReimbursementDetail()
        this.getReimbursement()
        this.getPowerList()
        this.isMobile()
        this.getUserId()
    },
    data() {
        return {
            title: '项目报销详情',
            id: '',
            reimbursementDetail: {
                projectName: '',//项目信息
                userName: '',//收款人
                price: '',//收款金额
                description: '',//收款说明
                detailList: [],
                fileList: []//附件
            },
            reimbursementOption: [],//报销类型,
            isReviewImg: false,//是否预览图片
            reViewImg: '',//预览的图片
            powerList: [],//value 1总经理 2财务 3行政
            routerPath: '/pjReimbursement',
            isProject: true,//是否是项目
            isComment: false,//是否显隐评论框
            pdf: false,//是否显隐pdf预览
            htmlTitle: '',//导出pdf的文件名
            equipment: false,//判断是否为移动端
            userId: '',//当前用户的id，
            projectBelong: ''
        }
    },
    methods: {
        getUserId() {
            this.userId = getToken('userId')
        },
        //获取权限渲染页面
        getPowerList() {
            this.powerList = JSON.parse(getToken('powerList') || '[]')
        },
        getReimbursementDetail() {
            getReimbursement(this.id).then(res => {
                this.reimbursementDetail = res.data.data
                this.projectBelong = this.reimbursementDetail.belong == 1 ? '大美' : '中创'
                this.htmlTitle = this.reimbursementDetail.projectName ? this.reimbursementDetail.projectName + '的报销单文件' : this.reimbursementDetail.userName + '的报销单文件'
                this.pageShare()
            })
        },
        getTime(time) {
            return timeChange(time)
        },
        getReviewedStatus(s) {
            return projectStatusText(s)
        },
        reViewFile(url) {
            if (url.indexOf('.jpg') !== -1 || url.indexOf('.jpeg') !== -1 || url.indexOf('.png') !== -1) {
                this.isReviewImg = true
                this.reViewImg = url
                return
            }
            this.$router.push({
                path: "/preview",
                query: { url: url, routerPath: `/reimbursementDetails?id=${this.id}` },
            });
        },
        getReimbursement() {
            getConfig(3).then((res) => {
                let obj = res.data.rows.map((it) => {
                    return {
                        label: it.name,
                        value: it.value,
                    };
                });
                this.reimbursementOption = obj;
            });
        },
        //财务最后确认
        finalConfirmation(val) {
            reimbursementUpdateState(this.id, val).then(res => {
                if (res.data.code == 200) {
                    Toast.success('完成操作')
                    this.getReimbursementDetail()
                }
            })
        },
        downloadAll() {
            this.reimbursementDetail.detailList.forEach(it => {
                it.fileList.forEach(item => {
                    fetch(item.url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
                        const a = document.createElement('a')
                        a.href = URL.createObjectURL(blob)
                        a.download = item.fileName  // 下载文件的名字
                        document.body.appendChild(a)
                        a.click()
                        document.body.removeChild(a) // 下载完成移除元素
                    })
                })
            })

        },
        //添加评论后刷新数据
        pageUpdate() {
            this.getReimbursementDetail()
            this.isComment = false
        },
        isMobile() {
            this.equipment = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? false : true
        },

        exportPDF() {
            this.downloadHtmlToPdf()
            // this.pdf = false
        },
        reimbursementRevoke() {
            if (this.reimbursementDetail.state > 0 || this.userId !== this.reimbursementDetail.userId) {
                return
            }
            Dialog.confirm({
                message: `确定撤销？`,
                confirmButtonColor: '#0092fe'
            }).then(() => {
                reimbursementDeltel([this.id]).then(res => {
                    if (res.data.code == 200) {
                        Toast.success('撤销成功')
                        this.$router.replace('/pjReimbursement');
                    }
                })
            })
        },
        resubmit() {
            this.$router.push({
                path: "/newPjReimbursement",
                query: { additional: this.reimbursementDetail.additional, route: "/pjReimbursement", detail: JSON.stringify(this.reimbursementDetail) },
            });
        },
        pageShare() {
            let name = this.reimbursementDetail.projectName ? this.reimbursementDetail.projectName + '的报销详情' : this.reimbursementDetail.userName + '的报销详情'
            console.log(name)
            ddShare('/reimbursementDetails?id=' + this.id, name)
        }
    }
}
</script>

<style lang="less" scoped>
.bkColor {
    width: 100vw;
    min-height: 100vh;
    padding-bottom: 9vh;
    background: #f6f6f6;
    overflow: auto;
}

.projectTitle {
    width: 100vw;
    background: #fff;
    text-align: left;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 2vh;
    position: relative;

    .T {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 1.5vh
    }

    .D {
        color: #F0A239;
        font-size: 1em;
    }

    .I {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 50%;
        right: 70px;
    }
}

.projectBody {
    background: #fff;
    margin-bottom: 2vh;
}

/deep/ .van-cell {
    flex-wrap: wrap;
    margin-bottom: 1vh;
}

/deep/ .van-cell__title {
    color: #797979;
}

/deep/ .van-field__label,
.van-cell__title {
    width: 100vw;

}

/deep/ .van-cell::after {
    border-bottom: none;
}

.fileList {
    width: 100%;
    padding: 10px 16px;
    box-sizing: border-box;
    font-size: 14px;
    color: #797979;

    .title {
        text-align: left;
    }

    .fileImg {
        width: 80px;
        height: 80px;
    }

    .fileName {
        width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.chart {
    width: 100vw;
    // height: 100%;
    padding: 16px;
    box-sizing: border-box;
    background: #fff;
}

.chart .title {
    height: 5vh;
    font-size: 1rem;
    text-align: left;
    line-height: 5vh;
}

.chart .table {
    // display: flex;
    overflow-x: auto;
    // white-space: nowrap;
    border: #e4e5e7;
    table-layout: fixed;
}

.chart .label {
    min-width: 150px;
    height: 5vh;
}

.projectReviewedList {
    padding: 10px 16px;
    background: #fff;

    .A {
        top: 30px !important;
    }

    .T {
        top: 35px !important
    }

    .U {
        top: 65px !important
    }

    .F {
        height: 110px !important;
    }
}

.title {
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
}

.reviewed {
    position: relative;
    height: 140px;
    padding: 10px 20px;
    box-sizing: border-box;
    overflow: hidden;

    .avatar {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 75px;
        left: 20px;
    }

    .status {
        position: absolute;
        top: 80px;
        left: 100px;
    }

    .time {
        position: absolute;
        top: 80px;
        right: 20px;
        color: #B4B4B4;
    }

    .userName {
        position: absolute;
        top: 110px;
        left: 100px;
        font-size: 1em;
        font-weight: 600;
    }
}

.projectFoot {
    margin-top: 2vh;
    width: 100vw;
    background: #fff;
    padding: 20px 0px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
}

#printer {
    width: 1391px;
    height: 100%;
    padding: 35px;
    margin: auto;
}

#printer p {
    font-size: 50px;
    font-weight: 600;
}

#printer table {
    table-layout: fixed !important;
    border-collapse: collapse !important;

}

#printer tr {
    text-align: center;
    vertical-align: middle;
    border: 1px solid #000;
    border-left: none;

}

#printer tr:first-child {
    border-left: 1px solid #000;
}

#printer td {
    height: 90px;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    font-size: 22px;
    word-break: break-all;
}

#printer td:first-child {
    border-left: 1px solid #000;
}

#printer .bold {
    font-weight: 600;
}

/deep/ .van-goods-action {
    background: #f6f6f6;
}

/deep/ .van-goods-action-icon {
    min-width: 18%;
    background: #f6f6f6;
    font-weight: 600;
}

/deep/ .van-button {
    border-radius: 6px;
}

.footContent {
    width: 100vw;
    height: 9vh;
    padding: 0 10px;
    box-sizing: border-box;
    position: fixed;
    // top: 0;
    // right: 0;
    bottom: 0;
    left: 0;
    background: #f6f6f6;
    display: flex;
    align-items: center;
}

.footButton {
    width: 60px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}
</style>